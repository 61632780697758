import React from 'react';
import { LOGIN_METHOD } from './store/useLoginStore';
import { SessionData } from 'store/useMeStore';
import BankID, { TYPE } from 'components/bankID/BankID';
import useLogin from './hooks/useLogin';
import api from 'api/Api';

interface Props {
    setLoading: (loading: boolean) => void;
    setMethod: (newLoginMethod: LOGIN_METHOD) => void;
    redirectedFrom: string | null;
}

export const LoginWithBankID = ({ setLoading, setMethod }: Props) => {
    const { setLoginData, loading } = useLogin();

    React.useEffect(() => {
        setLoading(loading);
    }, [loading]);

    const handleGoBack = () => {
        setMethod(LOGIN_METHOD.NORMAL);
    };

    return (
        <BankID<SessionData>
            onGoBack={handleGoBack}
            onSuccess={v => setLoginData(new Date(), v)}
            type={TYPE.AUTH}
            endPoint={api.user.loginDigitalID}
            title='Välkommen tillbaka!'
            subtitle='Välj identifieringsmetod för att fortsätta.'
        />
    );
};
